import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import { urls } from './config/urls';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./components/Error404')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: urls.signIn(),
    component: lazy(() => import('./views/login/LoginPage')),
  },
  {
    path: '*',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: urls.superAdminsList(),
        component: lazy(() => import('./views/superAdmins/SuperAdminsListLayout')),
      },
      {
        exact: true,
        path: urls.superAdminsCreate(),
        component: lazy(() => import('./views/superAdmins/SuperAdminsCreateLayout')),
      },
      {
        exact: true,
        path: urls.superAdminsDetail(),
        component: lazy(() => import('./views/superAdmins/SuperAdminsDetailLayout')),
      },
      {
        exact: true,
        path: urls.contactFormList(),
        component: lazy(() => import('./views/contactForm/ContactFormDetailLayout')),
      },
      {
        exact: true,
        path: urls.networksList(),
        component: lazy(() => import('./views/networks/NetworksListLayout')),
      },
      {
        exact: true,
        path: urls.networksCreate(),
        component: lazy(() => import('./views/networks/NetworksCreateLayout')),
      },
      {
        exact: true,
        path: [urls.networksDetailSection(), urls.networksDetail()],
        component: lazy(() => import('./views/networks/NetworksDetailLayout')),
      },
      {
        exact: true,
        path: urls.networkAdminsDetail(),
        component: lazy(() => import('./views/networkAdmins/NetworkAdminsDetailLayout')),
      },
      {
        exact: true,
        path: urls.networkAdminsCreate(), // todo try to refactor the url avoiding ${id}
        component: lazy(() => import('./views/networkAdmins/NetworkAdminsCreateLayout')),
      },
      {
        exact: true,
        path: urls.shopsList(),
        component: lazy(() => import('./views/shops/ShopsListLayout')),
      },
      {
        exact: true,
        path: urls.home(),
        component: lazy(() => import('./views/shops/ShopsListLayout')),
      },
      {
        exact: true,
        path: urls.reports(),
        component: lazy(() => import('./views/reports/ReportListLayout')),
      },
      {
        exact: true,
        path: urls.shopsCreate(),
        component: lazy(() => import('./views/shops/ShopsCreateLayout')),
      },
      {
        exact: true,
        path: [urls.shopsDetailSection(), urls.shopsDetail()],
        component: lazy(() => import('./views/shops/ShopsDetailLayout')),
      },
      {
        exact: true,
        path: urls.shopUsersCreate(), // todo try to refactor the url avoiding ${id}
        component: lazy(() => import('./views/shopUsers/ShopUsersCreateLayout')),
      },
      {
        exact: true,
        path: urls.shopUsersDetail(),
        component: lazy(() => import('./views/shopUsers/ShopUsersDetailLayout')),
      },
      {
        exact: true,
        path: urls.representativesList(),
        component: lazy(() => import('./views/representatives/RepresentativesListLayout')),
      },
      {
        exact: true,
        path: urls.representativesCreate(),
        component: lazy(() => import('./views/representatives/RepresentativesCreateLayout')),
      },
      {
        exact: true,
        path: urls.representativesDetail(),
        component: lazy(() => import('./views/representatives/RepresentativesDetailLayout')),
      },
      {
        exact: true,
        path: urls.zonesList(),
        component: lazy(() => import('./views/zones/ZonesListLayout')),
      },
      {
        exact: true,
        path: urls.zonesCreate(),
        component: lazy(() => import('./views/zones/ZonesCreateLayout')),
      },
      {
        exact: true,
        path: urls.zonesDetail(),
        component: lazy(() => import('./views/zones/ZonesDetailLayout')),
      },
      {
        exact: true,
        path: [urls.expeditionsDetailTab(), urls.expeditionsDetail()],
        component: lazy(() => import('./views/expeditions/ExpeditionDetailContainer')),
      },
      {
        exact: true,
        path: urls.salesDetail(),
        component: lazy(() => import('./views/sales/SalesDetailLayout')),
      },
      // {
      //   exact: true,
      //   path: urls.settings(),
      //   component: lazy(() => import('./views/configuration/ConfigLayout')),
      // },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
